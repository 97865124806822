<template>
  <div
    class="uploadLog"
    :class="{
      active: pos === 'center'
    }"
    v-show="!isFullModel"
  >
    <!-- <my-icon class="myIcon" :fontSize="16" :iconName="'icondaohanglan_wentifankui_bai'"></my-icon> -->
    <img class="myIcon" src="@/assets/images/icondaohanglan_wentifankui_bai.png"  alt="">

    <div class="desc" @click="logReportToServer">{{ $t('meeting.log') }}</div>

    <el-dialog
      custom-class="commonElDialog logElDialog"
      width="380px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal="false"
      append-to-body
      :visible.sync="logDialog"
    >
      <div class="dialog-content">
        <div  v-if='loading' class="item">
          <div class="icon loading"></div>
          <div class="text">{{$t('meeting.loging')}}</div>
        </div>

        <div v-if="!loading && uploadSuccess && !uploadEmpty" class="item">
          <div class="icon success"></div>
          <div class="text">{{$t('meeting.logSuccess')}}</div>
        </div>

        <div v-if="!loading && !uploadSuccess && !uploadEmpty" class="item">
          <div class="icon error"></div>
          <div class="text">{{$t('meeting.logFail')}}</div>
        </div>
        
        <div v-if="!loading && uploadEmpty" class="item">
          <div class="icon error"></div>
          <div class="text">上传日志失败，暂无日志</div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="logDialog = false">{{$t('login.sure')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Logan from "logan-web";
import { getUuid } from "@/utils/auth";
import { reportLogUrl } from '@/config/index';
import {getlocalUserId} from '@/utils/auth'

export default {
  data() {
    return {
      logDialog: false,
      loading: false,
      uploadSuccess: false,
      uploadEmpty : false
    };
  },
  props: {
    pos: {
      type: String,
      default: ''
    }
  },
  computed: {
    isFullModel() {
      return this.$store.state.meet.isFullModel
    },
  },
  methods: {
    // 上传日志
    async logReportToServer() {
      if(this.$store.state.meet.isNetworkDisconnect){
        this.$parent.showToast(this.$t('meeting.netWorkBad'))
        return
      }
      this.logDialog = true
      this.loading = true
      const { roomId, peerId, userId } = this.$configs
      // console.error(55555,userId)
      const currentTimestamp = new Date().getTime()

      const endDate = dayjs(currentTimestamp).format('YYYY-MM-DD')
      const startDate = dayjs(currentTimestamp).subtract(1, 'day').format('YYYY-MM-DD')

      try {
        let uniqueId = Math.floor(Math.random() * 1000000000) //唯一哈希值 同批次日志一个ID
        let res =  await Logan.report({
          reportUrl: reportLogUrl,
          deviceId: "web_" + getUuid(),
          fromDayString: startDate,
          toDayString: endDate,
          customInfo: JSON.stringify({
            peerId: peerId,
            roomId: roomId,
            userId: userId || getlocalUserId(), //如果是会场外并且非登录用户的话用缓存下来的user ID
            uniqueId: uniqueId
          }),
          webSource: 'ybmeet'
        })
        for (const key in res) {
          if (Object.hasOwnProperty.call(res, key)) {
            const element = res[key];
            if(element.msg !== 'Report succ') {
              this.loading = false
              this.uploadSuccess = false
              if(element.msg === 'No log exists'){
                this.uploadEmpty = true
              }else{
                this.uploadEmpty = false
              }
            }else{
              this.loading = false
              this.uploadEmpty = false
              this.uploadSuccess = true
            }
          }
        }
        console.log('上传日志',res)
      } catch (error) {
        console.error('上传日志失败',error)
        this.loading = false
        this.uploadEmpty = false
        this.uploadSuccess = false
      }
    }
  },
};
</script>

<style lang="scss" scoped>

.logElDialog {
  .dialog-content {
    .item {
      display: flex;
      align-items: center;

      .icon {
        width: 32px;
        height: 32px;
        margin-right: 8px;

        &.loading {
          background: url(~@/assets/images/loading.png) no-repeat;
          background-size: 100% 100%;
          display: block;
          animation: rotate 1s linear infinite;
        }

        &.success {
          background: url(~@/assets/images/icon-success.png) no-repeat;
          background-size: 100% 100%;
        }

        &.error {
          background: url(~@/assets/images/icon-error.png) no-repeat;
          background-size: 100% 100%;
        }
      }

      .text {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
      }
    }
  }
}

.uploadLog {
  height: 18px;
  line-height:14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  box-sizing: border-box;

  &.active {
    margin-right: 10px;
    box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    background: rgba(51, 51, 51, 0.4);

    &:hover {
      background: rgba(102, 102, 102, 0.3);
    }
  }
  .myIcon {
    margin-right: 6px;
    width:16px;
    height:16px;
  }

  .desc {
    height: 18px;
    line-height: 18px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.85);
    text-shadow: 0px 20px 50px rgba(0, 0, 0, 0.3);
    // margin: 3px 6px 3px 2px;

    &:hover {
      color: rgba(255, 255, 255,1);
    }
  }
}
</style>
