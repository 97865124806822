<template>
  <div class="login-content">
    <div class="logo">
      <img src="@/assets/images/menu/logo.svg" />
    </div>

    <div class="title">{{ $t('home.title') }}</div>
    <div class="join-button">
      <el-button @click="handleJoin" id="handle_join_btn_headless">{{$t('home.joinMeeting')}}</el-button>
    </div>
    <div class="login-button">
      <el-button @click="handleLogin" id="handle_login_btn_headless">{{$t('home.signIn')}}</el-button>
    </div>
    <div class="version-container">
      <div>{{ $t('home.version') }}100MEET v1.10.26 20241015</div>
    </div>
    <div class="downLoadWindow" v-show="isShow">
      <img class='img_style' src="@/assets/images/down.png" alt="">
      <my-icon class="closeIcon" :iconName="'iconxitonglan_guanbi_hei'" :fontSize="16" @click="innerClose"></my-icon>
      <a class="ddl" href="https://cloud.100doc.com.cn/product/ybMeeting/dashboard" target="_blank">
        <div class="downLoadTitle">{{ $t('home.titleTips') }}</div>
        <div class="quickJoinTitle" :style="{'margin-left':$i18n.locale === 'en-US'?'88px':'150px'}">{{ $t('home.quickJoin') }}</div>
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    name: "loginMenu",
    data() {
      return {
        isShow: true
      };
    },
    methods: {
      handleLogin() {
        this.$emit("handleLogin");
      },
      handleJoin() {
        this.$router.push({
          path: "join"
        });
      },
      innerClose() {
        this.isShow = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  @mixin btn_style($bg,$color){
    width: 360px;
    height: 40px;
    padding:0 10px;
    font-size: 14px;
    border-radius: 4px;
    background-color:$bg;
    border-color: #1ab370;
    color:$color;
  }
  .logo {
    overflow: hidden;
    margin-top: 100px;
    text-align: center;
  }

  .title {
    width: 160px;
    height: 28px;
    font-size: 20px;
    margin-top: 10px;
    font-weight: 600;
    color: #000000;
    line-height: 28px;
    text-align: center;
  }
  .join-button {
    margin-top: 58px;
    ::v-deep .el-button--default{
     @include btn_style(#1ab370, #fff);
      &:hover{
        background: #3BBF82;
      }
      &:focus{
        background: #0E8C5A;
      }
    }
  }

  .login-button {
    margin-top: 24px;
    ::v-deep .el-button--default{
     @include btn_style(#fff,#1ab370);
      &:hover{
        background: rgba(26, 179, 112, 0.1);
      }
      &:focus{
        background: rgba(26, 179, 112, 0.2);
      }
    } 
  }

  .downLoadWindow {
    width: 320px;
    height: 100px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 0px rgba(102, 102, 102, 0.1);
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: absolute;
    right: 16px;
    bottom: 64px;
    .closeIcon {
      margin: 10px 0 12px 294px;
      cursor: pointer;
    }
    .ddl {
      width: 300px;
      height: 100px;
    }
    .img_style {
      width: 60px;
      height: 60px;
      position: absolute;
      margin: 20px 24px;
    }
    @mixin title_style($size,$weight,$color){
      position: absolute;
      font-size: $size;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: $weight;
      color: $color;
    }
    .downLoadTitle {
      @include title_style(16px,500,#333333);
      margin-left: 116px;
      margin-top: -13px;
    }
    .quickJoinTitle{
      @include title_style(14px,300,#FF4D4F);
      margin-top: 14px;
    }
  }

  .version-container {
    padding-top: 24px;
    color: #999999;
    flex: 1;
  }

</style>